<template>
  <div v-if="widgetUrl !== null" class="overlay-container">
    <div id="overlay" class="dashed-border iframe-container" allowfullscreen>
      <iframe
        :src="widgetUrl"
        title="MX Connect Widget"
        frameborder="0"
        ref="iframeRef"
        id="mxiframe"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MxConnectWidget',
  props: {
    onEvent: Function,
    widgetUrl: String,
  },
  watch: {
    widgetUrl(newUrl) {
      if (newUrl) {
        this.openFullScreen();
      }
    },
  },
  mounted() {
    window.addEventListener('message', this.onPostMessage);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.onPostMessage);
  },
  methods: {
    onPostMessage(event) {
      if (event.data && event.data.mx === true) {
        this.onEvent(event.data);
      }
    },
    openFullScreen() {
      const iframe = this.$refs.iframeRef;
      if (iframe) {
        iframe.requestFullscreen();
      }
    },
  },
};
</script>

<style scoped>
body,
html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* Add this to prevent scrolling */
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.iframe-container {
  width: 100%;
  min-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.iframe-container iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
</style>


